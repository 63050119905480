import React from "react"
import Index from "../components/Cities/index";
export default function In(props) {
  const mainUrl = props.params["*"];
  const url = props.params["*"] ? props.params["*"].split("-") : [];

  const cityFitstName = url[2];
  const cityLastName = url[3];
  const state = url.length === 5 ? url[4] : url[3];

  console.log("state", state);
  const cityFullName =
    url.length === 5
      ? cityFitstName?.charAt(0)?.toUpperCase() +
        cityFitstName?.slice(1) +
        " " +
        cityLastName?.charAt(0)?.toUpperCase() +
        cityLastName?.slice(1)
      : cityFitstName?.charAt(0)?.toUpperCase() + cityFitstName?.slice(1) + "";

      console.log(mainUrl, url, '**Url');  
  return (
    <Index
      city={cityFullName ? cityFullName : "IN"}
      state={state?.toUpperCase() ? state?.toUpperCase() : "Arlington Heights"}
      url={mainUrl ? mainUrl : "/index"}
    />
  );
}
